import { formatDate } from '@angular/common';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.css'],
})
export class DeviceListComponent implements AfterViewInit {
  @ViewChild('mgtDevices', { read: ElementRef }) devices: ElementRef<HTMLElement>;

  async ngAfterViewInit(): Promise<void> {
    this.devices.nativeElement['templateContext'] = {
      formatDate(date: string) {
        return formatDate(date, 'medium', 'en');
      },
    };
  }
}
