import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { MsProviderManager } from 'mgt-components';

@Injectable({ providedIn: 'root' })
export class SignedInGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly providerManager: MsProviderManager
  ) {}

  canActivate() {
    if (this.providerManager.isSignedIn) {
      // Navigate as normal:
      return true;
    }

    this.router.navigate(['/sign-in'], { queryParamsHandling: 'merge' });
    return false;
  }
}
