<mgt-get class="content" resource="/devices" scopes="Directory.Read.All" #mgtDevices>
  <template>
    <div class="list-item" data-for="device in value">
      <a href="devices/[[ device.id ]]">
        <h4 class="list-item-header">
          [[ device.displayName ]] <small class="text-muted">[[ device.deviceId ]]</small>
        </h4>
      </a>
      <div class="list-item-content">
        <span><strong>OS:</strong> [[ device.operatingSystem ]]</span>
        <span><strong>OS Version:</strong> [[device.operatingSystemVersion ]]</span>
        <span
          ><strong>Last sign in:</strong>
          [[ formatDate(device.approximateLastSignInDateTime) ]]
        </span>
      </div>
    </div>
  </template>
</mgt-get>
