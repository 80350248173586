import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { TemplateRenderedEvent } from '../../types/template-rendered-event';

@Component({
  selector: 'ddmgt-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css'],
})
export class AgendaComponent {
  @Input('ddmgt-date') date?: Date;

  @Input('ddmgt-days') days?: number;

  @Input('ddmgt-show-max') showMax?: number;

  @Input('ddmgt-group-id') groupId?: string;

  @Input('ddmgt-event-query') eventQuery?: string;

  @Input('ddmgt-group-by-day') groupByDay?: boolean;

  @Input('ddmgt-default-template') defaultTemplate?: TemplateRef<any>;

  @Input('ddmgt-event-template') eventTemplate?: TemplateRef<any>;

  @Input('ddmgt-event-other') eventOtherTemplate?: TemplateRef<any>;

  @Input('ddmgt-header-template') headerTemplate?: TemplateRef<any>;

  @Input('ddmgt-loading-template') loadingTemplate?: TemplateRef<any>;

  @Input('ddmgt-no-data-template') noDataTemplate?: TemplateRef<any>;

  @Output('ddmgt-event-click') eventClickEmitter = new EventEmitter<any>();

  context: any;

  handleEventClick(event: any): void {
    this.eventClickEmitter.emit(event);
  }

  handleAgendaRendered(event: TemplateRenderedEvent): void {
    this.context = event.detail.context;
  }
}
