import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ddmgt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  @Output('ddmgt-login-initiated') loginInitiatedEmitter = new EventEmitter<void>();

  @Output('ddmgt-login-completed') loginCompletedEmitter = new EventEmitter<void>();

  @Output('ddmgt-login-failed') loginFailedEmitter = new EventEmitter<void>();

  @Output('ddmgt-logout-initiated') logoutInitiatedEmitter = new EventEmitter<void>();

  @Output('ddmgt-logout-completed') logoutCompletedEmitter = new EventEmitter<void>();
}
