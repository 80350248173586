import { Task } from './task';

export type ApiStatus = ApiSuccessStatus | ApiErrorStatus;

export type ApiSuccessStatus = 200 | 204;

export type ApiErrorStatus = 400 | 401 | 403 | 404 | 500;

export type ApiResult<T> = ApiSuccessResult<T> | ApiErrorResult;

export class ApiSuccessResult<T> {
  readonly status: ApiSuccessStatus = 200;

  constructor(readonly value: T) {}

  isError(): this is ApiErrorResult {
    return false;
  }

  map<TTarget>(fn: (v: T) => TTarget): ApiSuccessResult<TTarget> {
    return new ApiSuccessResult<TTarget>(fn(this.value));
  }
}

export class ApiErrorResult {
  constructor(readonly status: ApiErrorStatus, readonly message?: string) {}

  withMessage(message: string): ApiErrorResult {
    return new ApiErrorResult(this.status, message);
  }

  isError(): this is ApiErrorResult {
    return true;
  }

  map(): ApiErrorResult {
    return this;
  }
}

export type ApiResultPromise<T> = Promise<ApiResult<T>>;

export type ApiResultTask<T> = Task<ApiResult<T>>;

export function ApiNotFound(message?: string): ApiErrorResult {
  return new ApiErrorResult(404, message);
}

export function ApiForbidden(message?: string): ApiErrorResult {
  return new ApiErrorResult(403, message);
}

export function ApiBadRequest(message: string): ApiErrorResult {
  return new ApiErrorResult(400, message);
}

export function ApiUnexpectedError(message: string): ApiErrorResult {
  return new ApiErrorResult(500, message);
}

export function ApiSuccess<T>(value: T): ApiSuccessResult<T> {
  return new ApiSuccessResult(value);
}

export class ApiVoidSuccessResult {
  readonly status: ApiSuccessStatus = 204;

  constructor() {}

  isError(): this is ApiErrorResult {
    return false;
  }
}

export type ApiVoidResult = ApiVoidSuccessResult | ApiErrorResult;

export type ApiVoidResultPromise = Promise<ApiVoidResult>;

export type ApiVoidResultTask = Task<ApiVoidResult>;
