<div *ngIf="error">
  {{ error?.message }}
</div>

<div *ngIf="!error">
  <div *ngFor="let ticket of tickets; trackBy: ticketTrackBy">
    <a href="" (click)="handleTicketClick($event, ticket)">
      <h4>{{ ticket.name }}</h4>
    </a>
  </div>
</div>