import { formatDate } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css'],
})
export class DeviceComponent implements OnInit, AfterViewInit {
  @ViewChild('mgtDevice', { read: ElementRef }) devices: ElementRef<HTMLElement>;

  deviceId?: string;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.deviceId = this.route.snapshot.paramMap.get('deviceId');
  }

  ngAfterViewInit(): void {
    this.devices.nativeElement['templateContext'] = {
      formatDate(date: string) {
        return formatDate(date, 'medium', 'en');
      },
    };
  }
}
