<mgt-get resource="/devices/{{ deviceId }}" scopes="Directory.Read.All" #mgtDevice>
  <template>
    <app-header>
      <div class="header">
        <h3>[[ displayName ]]</h3>
      </div>
    </app-header>

    <div class="content">
      <app-card>
        <h3>[[ displayName ]]</h3>

        <div><strong>Device ID:</strong> [[ deviceId ]]</div>

        <div><strong>Operating system:</strong> [[ operatingSystem ]]</div>

        <div><strong>Operating system version:</strong> [[ operatingSystemVersion ]]</div>

        <div><strong>Last sign in:</strong> [[ formatDate(approximateLastSignInDateTime) ]]</div>

        <div>
          <strong>Compliance expiration:</strong>
          <span data-if="complianceExpirationDateTime != null"
            >[[ formatDate(complianceExpirationDateTime) ]]</span
          >
          <span data-else> - </span>
        </div>

        <div>
          <strong>Physical IDs:</strong>
          <p style="margin-left: 12px;" data-for="id in physicalIds">[[ id ]]</p>
        </div>

        <div class="actions">
          <a class="btn btn-primary" [routerLink]="['create-ticket']">Create ticket</a>
        </div>
      </app-card>
    </div>
  </template>
</mgt-get>
