import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignedInGuard } from '../route-guards';
import { CreateTicketComponent } from './components/create-ticket/create-ticket.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DeviceComponent } from './components/device/device.component';
import { SignInComponent } from './components/sign-in/sign-in.component';

const routes: Routes = [
  {
    path: 'sign-in',
    component: SignInComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [SignedInGuard],
  },
  {
    path: 'devices/:deviceId',
    component: DeviceComponent,
    canActivate: [SignedInGuard],
  },
  {
    path: 'devices/:deviceId/create-ticket',
    component: CreateTicketComponent,
    canActivate: [SignedInGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'corrected',
      enableTracing: false,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
