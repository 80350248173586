import { Component, OnInit } from '@angular/core';
import { Entity, FindTicketsResponse } from '@deskdirector/post-message-api';
import { PostMessageApiService } from 'projects/ms-graph-dashboard/src/services/post-message-api.service';

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.css'],
})
export class TicketListComponent implements OnInit {
  tickets: FindTicketsResponse['tickets'] = [];
  total = 0;
  error?: Error;

  constructor(private readonly postMessageApi: PostMessageApiService) {}

  async ngOnInit(): Promise<void> {
    const req = this.postMessageApi.data.tickets.findTickets({ skip: 0, take: 50 });

    try {
      const res = await req;

      this.tickets = res.tickets;
      this.total = res.totalResults;
    } catch (error) {
      console.error(error);
      this.error = new Error('Could not load tickets');
    }
  }

  handleTicketClick(event: MouseEvent, ticket: Entity): void {
    event.preventDefault();

    this.postMessageApi.navigation.deeplink({
      name: 'ticket',
      ticket_id: ticket.entityId.toString(),
    });
  }

  ticketTrackBy(_index: number, ticket: any): number {
    return ticket.entityId;
  }
}
