import { HttpHeaders } from '@angular/common/http';
import {
  ApiErrorResult,
  ApiErrorStatus,
  ApiSuccessResult,
  ApiVoidSuccessResult,
} from './api-result';
import { ApiErrorResponse } from './response-status';
import { Task } from './task';

export type HttpResult<E, A> = HttpErrorResult<E> | HttpSuccessResult<A>;

export type HttpResultPromise<E, A> = Promise<HttpResult<E, A>>;
export type HttpResultTask<E, A> = Task<HttpResult<E, A>>;

export type ResultInit = {
  headers: HttpHeaders;
  status: number;
  statusText: string;
  url?: string | null;
  ok: boolean;
};

export type SuccessResultInit<A> = ResultInit & {
  value: A;
};

export type ErrorResultInit<E> = ResultInit & {
  value?: E;
};

abstract class HttpResultBase {
  /**
   * All response headers.
   */
  readonly headers: HttpHeaders;
  /**
   * Response status code.
   */
  readonly status: number;
  /**
   * Textual description of response status code.
   *
   * Do not depend on this.
   */
  readonly statusText: string;
  /**
   * URL of the resource retrieved, or undefined if not available.
   */
  readonly url?: string | null;
  /**
   * Whether the status code falls in the 2xx range.
   */
  readonly ok: boolean;

  constructor(protected readonly init: ResultInit) {
    this.headers = init.headers;
    this.status = init.status;
    this.statusText = init.statusText;
    this.url = init.url;
    this.ok = init.ok;
  }
}

export class HttpSuccessResult<A> extends HttpResultBase {
  readonly value: A;

  constructor(init: SuccessResultInit<A>) {
    super(init);
    this.value = init.value;
  }

  isSuccess(): this is HttpSuccessResult<A> {
    return true;
  }

  isError(): false {
    return false;
  }

  map<B>(fn: (val: A) => B): HttpSuccessResult<B> {
    return new HttpSuccessResult({ ...this.init, value: fn(this.value) });
  }

  mapError(): HttpSuccessResult<A> {
    return this;
  }

  toApiResult(): ApiSuccessResult<A> {
    return new ApiSuccessResult(this.value);
  }
}

export class HttpErrorResult<E> extends HttpResultBase {
  readonly value?: E;

  constructor(init: ErrorResultInit<E>) {
    super(init);
    this.value = init.value;
  }

  isSuccess(): false {
    return false;
  }

  isError(): this is HttpErrorResult<E> {
    return true;
  }

  map(): HttpErrorResult<E> {
    return this;
  }

  mapError<B>(fn: (val: E) => B): HttpErrorResult<B> {
    return new HttpErrorResult({ ...this.init, value: fn(this.value) });
  }

  toApiResult(message?: string): ApiErrorResult {
    let status: ApiErrorStatus | null = null;
    switch (this.status) {
      case 400:
        status = 400;
        break;

      case 401:
        status = 401;
        break;

      case 403:
        status = 403;
        break;

      case 404:
        status = 404;
        break;

      default:
        status = 500;
        break;
    }

    return new ApiErrorResult(
      status,
      message ??
        (this.value as unknown as ApiErrorResponse)?.responseStatus?.message ??
        'unknown error'
    );
  }
}

export class HttpVoidSuccessResult extends HttpResultBase {
  constructor(init: ResultInit) {
    super(init);
  }

  isSuccess(): this is HttpVoidSuccessResult {
    return true;
  }

  isError(): false {
    return false;
  }

  toApiResult(): ApiVoidSuccessResult {
    return new ApiVoidSuccessResult();
  }
}

export type HttpVoidResult<E> = HttpVoidSuccessResult | HttpErrorResult<E>;

export type HttpVoidResultPromise<E> = Promise<HttpVoidResult<E>>;

export type HttpVoidResultTask<E> = Task<HttpVoidResult<E>>;
