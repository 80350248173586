import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CommonQueryParamsService {
  /**
   * Gets the value for the query param 'host'
   */
  get host(): URL | undefined {
    const param = this.params.get('host');

    if (param == null) {
      return;
    }

    const host = decodeURIComponent(param);

    try {
      return new URL(host);
    } catch (error) {
      console.error(error);
      return;
    }
  }

  /**
   * Gets the value for the query param 'raven_name'
   */
  get ravenName(): string | undefined {
    return this.params.get('raven_name') ?? undefined;
  }

  /**
   * Gets the value for the query param 'access_token'
   */
  get accessToken(): string | undefined {
    return this.params.get('access_token') ?? undefined;
  }

  protected get params(): URLSearchParams {
    return new URLSearchParams(window.location.search);
  }
}
