import { Injectable, NgZone } from '@angular/core';
import { MsalConfig, MsalProvider, Providers, ProviderState } from '@microsoft/mgt';

export type LoginStateChangeHandler = (state: ProviderState) => any;

@Injectable({ providedIn: 'root' })
export class MsProviderManager {
  get provider(): MsalProvider | undefined {
    return this._provider;
  }

  get isSignedIn(): boolean {
    return this.provider?.state === ProviderState.SignedIn;
  }

  get isSignedOut(): boolean {
    return this.provider?.state === ProviderState.SignedOut;
  }

  get isLoading(): boolean {
    return this.provider?.state === ProviderState.Loading;
  }

  get isManagerInitialized(): boolean {
    return this._isManagerInitialized;
  }

  private _isManagerInitialized = false;
  private _provider?: MsalProvider;

  constructor(private readonly zone: NgZone) {}

  async initialize(config: MsalConfig, stateChangeHandler: LoginStateChangeHandler): Promise<void> {
    if (this._provider != null) {
      return;
    }

    this._provider = new MsalProvider(config);

    this._provider.onStateChanged(() => {
      this.zone.run(() => {
        if (this._provider == null) {
          return;
        }

        stateChangeHandler(this._provider.state);
      });
    });

    Providers.globalProvider = this._provider;

    await this._provider.trySilentSignIn();
  }
}
