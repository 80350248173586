import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoginType, ProviderState } from '@microsoft/mgt';
import { MgtComponentsModule, MsProviderManager } from 'mgt-components';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardComponent } from './components/card/card.component';
import { CreateTicketComponent } from './components/create-ticket/create-ticket.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DeviceListComponent } from './components/device-list/device-list.component';
import { DeviceComponent } from './components/device/device.component';
import { HeaderComponent } from './components/header/header.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { TicketListComponent } from './components/ticket-list/ticket-list.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    DashboardComponent,
    HeaderComponent,
    DeviceListComponent,
    DeviceComponent,
    CreateTicketComponent,
    CardComponent,
    TicketListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    MgtComponentsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (router: Router, providerManager: MsProviderManager) => async () => {
        await providerManager.initialize(
          {
            clientId: 'ff51c0ce-b6fc-4d93-a2b5-96cf6f537335',
            scopes: [
              'Calendars.Read',
              'Contacts.Read',
              'Device.Read.All',
              'Group.Read.All',
              'Group.ReadWrite.All',
              'People.Read',
              'Presence.Read',
              'Presence.Read.All',
              'Tasks.Read',
              'Tasks.ReadWrite',
              'User.Read',
              'User.ReadBasic.All',
            ],
            authority: 'https://login.microsoftonline.com/organizations/',
            redirectUri: window.location.origin,
            loginType: LoginType.Popup,
          },
          state => {
            if (state === ProviderState.SignedOut) {
              router.navigate(['sign-in'], { queryParamsHandling: 'merge' });
              return;
            }

            const signInUrlTree = router.createUrlTree(['sign-in']);
            const signInIsActive = router.isActive(signInUrlTree, true);

            if (signInIsActive) {
              router.navigate(['dashboard'], { queryParamsHandling: 'merge' });
              return;
            }
          }
        );
      },
      deps: [Router, MsProviderManager],
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
