<app-header>
  <div class="header">
    <h3>Create ticket</h3>
  </div>
</app-header>

<mgt-get
  resource="/devices/{{ deviceId }}"
  scopes="Directory.Read.All"
  (dataChange)="handleDataChange($event)"
  #mgtDevice
></mgt-get>

<form novalidate class="content" [formGroup]="form">
  <app-card>
    <h3>Create a new ticket</h3>
    <div class="dd-form-control">
      <label>Summary</label>
      <input class="dd-input" type="text" formControlName="summary" />
    </div>

    <div class="dd-form-control">
      <label>Description</label>
      <textarea class="dd-input" rows="10" formControlName="description"></textarea>
    </div>

    <p [style.color]="'red'" *ngIf="errorMessage && !result">{{ errorMessage }}</p>

    <a href="" *ngIf="result && !errorMessage" (click)="handleViewTicketClick()">View ticket</a>

    <div class="actions">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="form.invalid || isBusy"
        (click)="handleCreateClick()"
      >
        Create
      </button>
    </div>
  </app-card>
</form>
