import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { TemplateRenderedEvent } from '../../types/template-rendered-event';

@Component({
  selector: 'ddmgt-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css'],
})
export class TasksComponent {
  @Input('ddmgt-data-source') dataSource?: 'planner' | 'todo';

  @Input('ddmgt-read-only') readOnly?: boolean;

  @Input('ddmgt-hide-header') hideHeader?: boolean;

  @Input('ddmgt-hide-options') hideOptions?: boolean;

  @Input('ddmgt-initial-id') initialId?: string;

  @Input('ddmgt-initial-bucket-id') initialBucketId?: string;

  @Input('ddmgt-target-id') targetId?: string;

  @Input('ddmgt-target-bucket-id') targetBucketId?: string;

  @Input('ddmgt-group-id') groupId?: string;

  @Input('ddmgt-task-template') taskTemplate?: TemplateRef<any>;

  @Input('ddmgt-task-details-template') taskDetailsTemplate?: TemplateRef<any>;

  @Output('ddmgt-task-click') taskClickEmitter = new EventEmitter<any>();

  context: any;

  handleTaskClick(event: any): void {
    this.taskClickEmitter.emit(event);
  }

  handleTasksRendered(event: TemplateRenderedEvent): void {
    this.context = event.detail.context;
  }
}
