import { InjectionToken } from '@angular/core';
import { AccessTokenManager } from './types';

export const AccessTokenManagerInjectionToken = new InjectionToken<AccessTokenManager>(
  'common:access-token-manager'
);

export const MspNameInjectionToken = new InjectionToken<string>('common:msp-name');

export const HostInjectionToken = new InjectionToken<URL>('common:host');
