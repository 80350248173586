<app-header>
  <div class="header">
    <h3>Dashboard</h3>
  </div>
</app-header>

<div class="content">
  <app-card class="list-card">
    <h3>Agenda</h3>
    <ddmgt-agenda [ddmgt-no-data-template]="agendaNoDataTemplate"></ddmgt-agenda>
  </app-card>

  <ng-template #agendaNoDataTemplate>
    <div class="empty-state">
      <h4>Nothing on your agenda</h4>
      <img src="assets/search.png" alt="" />
    </div>
  </ng-template>

  <app-card class="list-card tasks">
    <h3>Tasks</h3>
    <ddmgt-tasks></ddmgt-tasks>
  </app-card>

  <app-card class="list-card">
    <header class="list-card-header">
      <h3 [style.margin]="'0'">Tickets</h3>
      <button class="btn btn-primary" [style.margin]="0" (click)="handleViewAllTicketsClick()">
        View all
      </button>
    </header>
    <app-ticket-list></app-ticket-list>
  </app-card>

  <app-card class="list-card">
    <h3>Devices</h3>
    <app-device-list></app-device-list>
  </app-card>
</div>
