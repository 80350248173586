import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Entity } from '@deskdirector/post-message-api';
import { stripIndents } from 'common-tags';
import { ApiService } from 'projects/ms-graph-dashboard/src/services';
import { PostMessageApiService } from 'projects/ms-graph-dashboard/src/services/post-message-api.service';
import { GetDeviceResponse } from '../../models';

type FormState = {
  summary: string;
  description: string;
};

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.css'],
})
export class CreateTicketComponent implements OnInit {
  form = new FormGroup({
    summary: new FormControl('', { validators: [Validators.required] }),
    description: new FormControl('', { validators: [Validators.required] }),
  });

  isBusy = false;
  deviceId?: string;
  errorMessage?: string;
  result?: Entity;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly api: ApiService,
    private readonly postMessageApi: PostMessageApiService
  ) {}

  ngOnInit(): void {
    this.deviceId = this.route.snapshot.paramMap.get('deviceId');
  }

  handleDataChange(event: any): void {
    if (event.detail.error) {
      console.error(event.detail.error);
      return;
    }

    if (event.detail.response == null) {
      return;
    }

    const deviceName = event.detail.response.displayName;

    const summary = this.form.get('summary');
    const description = this.form.get('description');

    summary.setValue(`I'm having an issue with device named: ${deviceName}`);
    description.setValue(this.formatDescription(event.detail.response));
  }

  async handleCreateClick(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    const value: FormState = this.form.value;

    try {
      this.isBusy = true;

      const res = await this.api.createTicket({
        name: value.summary,
        description: value.description,
      });

      if (res.isError()) {
        this.errorMessage = 'Failed to create ticket';
        return;
      }

      this.result = res.value;
    } catch (error) {
      this.errorMessage = 'Failed to create ticket';
    } finally {
      this.isBusy = false;
    }
  }

  handleViewTicketClick(): void {
    if (this.result == null) {
      return;
    }

    this.postMessageApi.navigation.deeplink({
      name: 'ticket',
      ticket_id: this.result.entityId.toString(),
    });
  }

  private formatDescription(res: GetDeviceResponse): string {
    return stripIndents`
      [[[ ENTER YOUR PROBLEM DESCRIPTION HERE ]]]

      ---

      ### Device details

      ${this.formatLine('Device Name', res.displayName)}
      ${this.formatLine('Device ID', res.deviceId)}
      ${this.formatLine('Device Operating System', res.operatingSystem)}
      ${this.formatLine('Device Operating System Version', res.operatingSystemVersion)}
      ${this.formatLine('Last Sign In', res.approximateLastSignInDateTime)}
      ${this.formatLine('Compliance Date Time', res.complianceExpirationDateTime)}
      ${this.formatList('Physical IDs', res.physicalIds)}
    `.trim();
  }

  private formatLine(fieldname: string, value: any): string {
    return `**${fieldname}**: ${value ?? '-'}`;
  }

  private formatList(fieldname: string, value: string[]): string {
    if (value.length === 0) {
      return `**${fieldname}**: -`;
    }

    return `**${fieldname}**:\n\n` + value.map(v => `* ${v}`).join('\n');
  }
}
