import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsProviderManager } from 'mgt-components';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
})
export class SignInComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly providerManager: MsProviderManager
  ) {}

  ngOnInit(): void {
    if (this.providerManager.isSignedIn) {
      this.router.navigate(['dashboard'], { queryParamsHandling: 'merge' });
    }
  }

  handleLoginCompleted(): void {
    this.router.navigate(['dashboard'], { queryParamsHandling: 'merge' });
  }
}
