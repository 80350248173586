import { Component, OnInit } from '@angular/core';
import { PostMessageApiService } from 'projects/ms-graph-dashboard/src/services/post-message-api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  constructor(private readonly postMessageApi: PostMessageApiService) {}

  ngOnInit(): void {}

  handleViewAllTicketsClick(): void {
    this.postMessageApi.navigation.deeplink({
      name: 'ticket_list',
    });
  }
}
