import { Injectable } from '@angular/core';
import { PortalApi } from '@deskdirector/post-message-api';
import { CommonQueryParamsService } from 'projects/_common/services';

@Injectable({ providedIn: 'root' })
export class PostMessageApiService {
  private readonly api: PortalApi;

  constructor(private readonly queryParams: CommonQueryParamsService) {
    const parent = window.parent === window ? window.opener : window.parent;
    this.api = new PortalApi(parent, this.queryParams.host?.origin ?? '');
    this.navigation = this.api.navigation;
    this.data = this.api.data;
  }

  navigation: PortalApi['navigation'];

  data: PortalApi['data'];
}
