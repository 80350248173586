import { Injectable } from '@angular/core';
import { HttpResult, JsonApiClient } from '@deskdirector/http';
import { environment } from '../environments/environment';

export type CreateTicketRequest = {
  name: string;
  description: string;
};

export type CreateTicketResponse = {
  name: string;
  entityId: number;
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private readonly http: JsonApiClient) {}

  createTicket(req: CreateTicketRequest): Promise<HttpResult<any, CreateTicketResponse>> {
    return this.http.post(
      environment.baseApiUrl + 'api/v2/contact/tickets/create',
      {
        ...req,
        typeEntityId: 0,
      },
      { withCredentials: true }
    );
  }
}
