import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TemplateHelper } from '@microsoft/mgt';
import { AgendaComponent, LoginComponent, TasksComponent } from './components';

TemplateHelper.setBindingSyntax('[[', ']]');

@NgModule({
  declarations: [LoginComponent, AgendaComponent, TasksComponent],
  imports: [BrowserModule, CommonModule, RouterModule],
  exports: [LoginComponent, AgendaComponent, TasksComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MgtComponentsModule {}
