import { Injectable } from '@angular/core';
import {
  ApiErrorResult,
  ApiResultPromise,
  ApiResultTask,
  ApiSuccess,
  ApiUnexpectedError,
  Task,
} from '@deskdirector/http';
import {
  AccessTokenResponse,
  isError,
  isSuccess,
  MessageErrorResult,
} from '@deskdirector/post-message-api';
import { addSeconds } from 'projects/utils/time';
import { AccessTokenManager, CacheEntity, expireInSec } from '../types';
import { CommonQueryParamsService } from './common-query-params.service';
import { PostMessageApiService } from './post-message-api.service';

// 15 min
const safeGap: number = 15 * 60;
const minExpireIn: number = 15 * 60;
const maxExpireIn: number = 45 * 60;

@Injectable({ providedIn: 'root' })
export class PostMessageAccessTokenManagerService implements AccessTokenManager {
  private cache?: CacheEntity<AccessTokenResponse>;
  private task?: ApiResultTask<AccessTokenResponse>;

  constructor(queryParams: CommonQueryParamsService, private readonly api: PostMessageApiService) {
    const token = queryParams?.accessToken;
    if (typeof token === 'string' && token.length > 50) {
      this.cache = expireInSec(
        {
          userKey: token,
          expires_in: minExpireIn,
          expires_on: addSeconds(new Date(), minExpireIn).getTime() / 1_000,
        },
        minExpireIn
      );
    }
  }

  async get(): ApiResultPromise<string> {
    const exist = this.cache?.value;
    if (exist?.userKey) {
      return ApiSuccess(exist.userKey);
    }

    return await this.refresh();
  }

  async refresh(): ApiResultPromise<string> {
    let task = this.task;
    if (task?.isCompleted === false) {
      const exist = await task.promise;
      if (exist.isError()) {
        return exist;
      }

      const existKey = exist.value?.userKey;
      return existKey
        ? ApiSuccess(existKey)
        : new ApiErrorResult(500, 'Successful result with empty user key');
    }

    task = this.task = new Task(this.fetch());
    const result = await task.promise;
    if (result.isError()) {
      return result;
    }

    const key = result.value?.userKey;
    const expireIn = result.value?.expires_in;
    if (!key || !expireIn) {
      return new ApiErrorResult(500, 'Successful result with empty user key');
    }

    let expire = expireIn - safeGap;
    expire = expire < minExpireIn || expire > maxExpireIn ? minExpireIn : expire;
    this.cache = expireInSec(result.value, expire);
    return ApiSuccess(key);
  }

  private async fetch(): ApiResultPromise<AccessTokenResponse> {
    try {
      const result = await this.api.userKey();
      if (isSuccess<AccessTokenResponse>(result)) {
        return ApiSuccess(result.value);
      }
      if (isError(result)) {
        return this.fromError(result);
      }

      return new ApiErrorResult(500, 'Invalid format message result');
    } catch (error) {
      const msg = 'Fail to fetch user key through post message';
      console.error(msg, error);
      return ApiUnexpectedError(msg);
    }
  }

  private fromError(result: MessageErrorResult): ApiErrorResult {
    switch (result.status) {
      case 400:
      case 401:
      case 403:
      case 404:
        return new ApiErrorResult(result.status, result.error?.message ?? 'Empty error message');

      case 500:
        return new ApiErrorResult(500, result.error?.message ?? 'Empty error message');
    }
  }
}
