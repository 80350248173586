import { HttpHeaders } from '@angular/common/http';
import { Inject } from '@angular/core';
import {
  ApiErrorResponse,
  ApiResultPromise,
  ApiVoidResultPromise,
  HttpMethod,
  JsonApiClient,
  RequestOptions,
} from '@deskdirector/http';
import { AccessTokenManagerInjectionToken } from 'projects/_common/injection-tokens';
import { AccessTokenManager } from 'projects/_common/types';

function addToken(token: string, options: RequestOptions): RequestOptions {
  if (options == null) {
    options = {};
  }

  if (options.headers == null) {
    options.headers = new HttpHeaders();
  }

  options.headers.delete('Authorization');
  options.headers.set('Authorization', `Bearer ${token}`);
  if (options.withCredentials == null) {
    options.withCredentials = true;
  }

  return options;
}

export class DdApiClient {
  constructor(
    private readonly http: JsonApiClient,
    @Inject(AccessTokenManagerInjectionToken)
    private readonly tokenManager: AccessTokenManager
  ) {}

  get<T>(url: string, options: RequestOptions = {}): ApiResultPromise<T> {
    return this.fetch('GET', url, options);
  }

  post<T>(url: string, body: any, options: RequestOptions = {}): ApiResultPromise<T> {
    return this.fetch('POST', url, { ...options, body });
  }

  put<T>(url: string, body: any, options: RequestOptions = {}): ApiResultPromise<T> {
    return this.fetch('PUT', url, { ...options, body });
  }

  patch<T>(url: string, body: any, options: RequestOptions = {}): ApiResultPromise<T> {
    return this.fetch('PATCH', url, { ...options, body });
  }

  async fetch<T>(method: HttpMethod, url: string, options: RequestOptions): ApiResultPromise<T> {
    let token = await this.tokenManager.get();
    if (token.isError()) {
      return token;
    }

    let res = await this.http.request<ApiErrorResponse, T>(
      method,
      url,
      addToken(token.value, options)
    );
    if (res.isSuccess()) {
      return res.toApiResult();
    }

    if (res.status !== 401) {
      return res.toApiResult();
    }

    token = await this.tokenManager.refresh();
    if (token.isError()) {
      return token;
    }

    res = await this.http.request<ApiErrorResponse, T>(method, url, addToken(token.value, options));
    return res.toApiResult();
  }

  postVoid(url: string, body: any, options: RequestOptions = {}): ApiVoidResultPromise {
    return this.voidFetch('POST', url, { ...options, body });
  }

  putVoid(url: string, body: any, options: RequestOptions = {}): ApiVoidResultPromise {
    return this.voidFetch('PUT', url, { ...options, body });
  }

  patchVoid(url: string, body: any, options: RequestOptions = {}): ApiVoidResultPromise {
    return this.voidFetch('PATCH', url, { ...options, body });
  }

  async voidFetch(method: HttpMethod, url: string, options: RequestOptions): ApiVoidResultPromise {
    let token = await this.tokenManager.get();
    if (token.isError()) {
      return token;
    }

    let res = await this.http.voidRequest<ApiErrorResponse>(
      method,
      url,
      addToken(token.value, options)
    );
    if (res.isSuccess()) {
      return res.toApiResult();
    }

    if (res.status !== 401) {
      return res.toApiResult();
    }

    token = await this.tokenManager.refresh();
    if (token.isError()) {
      return token;
    }

    res = await this.http.voidRequest<ApiErrorResponse>(
      method,
      url,
      addToken(token.value, options)
    );
    return res.toApiResult();
  }
}
