import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HttpErrorResult,
  HttpResult,
  HttpSuccessResult,
  HttpVoidResult,
  HttpVoidSuccessResult,
} from './http-result';

export type HttpMethod = 'DELETE' | 'GET' | 'HEAD' | 'JSONP' | 'OPTIONS' | 'POST' | 'PUT' | 'PATCH';

export type RequestOptions = {
  body?: any;
  headers?: HttpHeaders;
  reportProgress?: boolean;
  params?: HttpParams;
  responseType?: 'json';
  withCredentials?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class JsonApiClient {
  constructor(private readonly http: HttpClient) {}

  async request<E, A>(
    method: HttpMethod,
    url: string,
    options: RequestOptions
  ): Promise<HttpResult<E, A>> {
    try {
      const result = await this.http
        .request<A>(method, url, {
          ...options,
          observe: 'response',
          responseType: 'json',
        })
        .toPromise();
      // Throw it early and knows where the location is. Avoid nullreference exception.
      if (result.body == null) {
        throw new Error(`Query to ${url} success with no body`);
      }
      return new HttpSuccessResult<A>({ ...result, value: result.body });
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        console.log('HTTP request failed', error);
        return new HttpErrorResult<E>({ ...error, value: error.error });
      }
      throw error;
    }
  }

  async voidRequest<E>(
    method: HttpMethod,
    url: string,
    options: RequestOptions
  ): Promise<HttpVoidResult<E>> {
    try {
      const result = await this.http
        .request(method, url, {
          ...options,
          observe: 'response',
        })
        .toPromise();
      return new HttpVoidSuccessResult(result);
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        console.log('HTTP request failed', error);
        return new HttpErrorResult<E>({ ...error, value: error.error });
      }
      throw error;
    }
  }

  get<E, A>(url: string, options: RequestOptions = {}): Promise<HttpResult<E, A>> {
    return this.request('GET', url, options);
  }

  voidGet<E>(url: string, options: RequestOptions = {}): Promise<HttpVoidResult<E>> {
    return this.voidRequest('GET', url, options);
  }

  patch<E, A>(
    url: string,
    body: any | null,
    options: Omit<RequestOptions, 'body' | 'responseType'> = {}
  ): Promise<HttpResult<E, A>> {
    return this.request('PATCH', url, { ...options, body });
  }

  voidPatch<E>(
    url: string,
    body: any | null,
    options: Omit<RequestOptions, 'body' | 'responseType'>
  ): Promise<HttpVoidResult<E>> {
    return this.voidRequest<E>('PATCH', url, { ...options, body });
  }

  post<E, A>(
    url: string,
    body: any | null,
    options: Omit<RequestOptions, 'body' | 'responseType'> = {}
  ): Promise<HttpResult<E, A>> {
    return this.request('POST', url, { ...options, body });
  }

  voidPost<E>(
    url: string,
    body: any | null,
    options: Omit<RequestOptions, 'body' | 'responseType'> = {}
  ): Promise<HttpVoidResult<E>> {
    return this.voidRequest('POST', url, { ...options, body });
  }

  put<E, A>(
    url: string,
    body: any | null,
    options: Omit<RequestOptions, 'body' | 'responseType'> = {}
  ): Promise<HttpResult<E, A>> {
    return this.request('PUT', url, { ...options, body });
  }

  voidPut<E>(
    url: string,
    body: any | null,
    options: Omit<RequestOptions, 'body' | 'responseType'> = {}
  ): Promise<HttpVoidResult<E>> {
    return this.voidRequest('PUT', url, { ...options, body });
  }
}
